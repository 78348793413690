






































































import Component from 'vue-class-component'
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import CloudArrowDownIcon from '@/assets/icons/heroicons/CloudArrowDownIcon.vue';

@Component({
  components: { SygniRoundedButton, GenproxPageHeading, GenproxButton, CloudArrowDownIcon },
})
export default class PageDetails extends Vue {
  CloudArrowDownIcon = CloudArrowDownIcon;

  @Prop() category!: String;
  @Prop() title!: String;
  @Prop() disableSaveToPdf: boolean;

  dragActive: boolean = false;

  get description() {
    return this.disableSaveToPdf ? 'Upload your DATMAN or DATIF XML files for KNF reporting to review their content in readable form.' : this.category
  }

  async uploadXmlFile(e: Event, files: string | any[]) {
    e.preventDefault();
    this.dragActive = false;

    if (files.length) {
      if (files.length == 1) {
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        if (file.type !== "text/xml") {
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: 'Wrong file format'
          });
        } else {
          try {
            const apiQuery = '/regulatory-reporting/knf-report-xml/upload';
            const routeType = this.$route?.path?.includes('/fund-manager') ? 'aifm' : this.$route?.path?.includes('/tools') ? 'tools' : 'fund' 
            const route = `${routeType}-reg-xml-preview`;

            const data = await this.$store.dispatch('regReporting/uploadXmlFile', { file, apiQuery });
            this.$router.push({ name: route, query: { id: data.id } })
          } catch (e) {
            this.$notify({
              duration: 2500,
              type: 'error',
              title: 'Error',
              text: 'Something went wrong, please try again later.'
            });
          }
        }
      } else {
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Multiple files',
          text: 'You can select only single file to upload.'
        });
      }
    }
  }

  dragFileOver(e: Event) {
    e.preventDefault();
  }

  dragFileLeave() {
    this.dragActive = false
  }

  printPage() {
    window.print();
  }
}

