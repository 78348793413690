







































































import Component from 'vue-class-component'
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import CloudArrowDownIcon from '@/assets/icons/heroicons/CloudArrowDownIcon.vue';

@Component({
  components: { SygniRoundedButton, GenproxPageHeading, GenproxButton, CloudArrowDownIcon },
})
export default class XmlPageDetails extends Vue {
  CloudArrowDownIcon = CloudArrowDownIcon;
  
  @Prop() urlType: string;
  @Prop() disableSaveToPdf: boolean;
  @Prop() title!: String;
  @Prop() description!: String;
  dragActive: boolean = false;

  async uploadXmlFile(e: Event, files: string | any[]) {
    e.preventDefault();
    this.dragActive = false;

    if (files.length) {
      if (files.length == 1) {
        const file = files[0];
        const formData = new FormData();
        const fileType = file.name.split('.').pop()
        formData.append('file', file);
        const xadesCheck = this.urlType === 'xml' ? fileType.toLowerCase() != 'xades' : true;
        if (file.type !== "text/xml" && xadesCheck) {
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: 'Wrong file format'
          });
        } else {
          try {
            const {urlType} = this;
            const apiQuery = '/regulatory-reporting/knf-report-xml/upload'
            let route;

            const routeType = this.$route?.path?.includes('/fund-manager') ? 'aifm' : this.$route?.path?.includes('/tools') ? 'tools' : 'fund' 

            if (urlType === 'knf') {
              route = `${routeType}-reg-xml-preview`;
            } else if (urlType == 'xml') {
              route = `${routeType}-xml-preview`;
            }

            const data = await this.$store.dispatch('regReporting/uploadXmlFile', {file, apiQuery});
            this.$router.push({name: route, query: {id: data.id}})
          } catch (e) {
            this.$notify({
              duration: 2500,
              type: 'error',
              title: 'Error',
              text: 'Something went wrong, please try again later.'
            });
          }
        }
      } else {
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Multiple files',
          text: 'You can select only single file to upload.'
        });
      }
    }
  }

  printPage() {
    window.print();
  }

  dragFileLeave() {
    this.dragActive = false
  }

  dragFileOver(e: Event) {
    e.preventDefault();
  }
}
