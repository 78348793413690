var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "xml-preview-head"
  }, [_c('GenproxPageHeading', {
    attrs: {
      "title": _vm.title,
      "description": _vm.description
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('GenproxButton', {
          staticClass: "xml-preview-head__btn",
          attrs: {
            "isDisabled": _vm.disableSaveToPdf,
            "icon": _vm.CloudArrowDownIcon
          },
          on: {
            "click": function click($event) {
              return _vm.printPage();
            }
          }
        }, [_vm._v("Save to PDF")])];
      },
      proxy: true
    }])
  }), _c('div', {
    class: ['droparea', _vm.dragActive ? 'droparea--active' : ''],
    on: {
      "dragenter": function dragenter($event) {
        _vm.dragActive = true;
      },
      "dragleave": _vm.dragFileLeave,
      "dragover": _vm.dragFileOver,
      "drop": function drop($event) {
        return _vm.uploadXmlFile($event, $event.dataTransfer.files);
      }
    }
  }, [_c('div', {
    staticClass: "droparea__btn"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
    }
  })])]), _c('div', {
    staticClass: "droparea__text"
  }, [_c('input', {
    ref: "fileUpload1",
    attrs: {
      "id": "fileUpload1",
      "accept": "text/xml",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadXmlFile($event, $event.target.files);
      }
    }
  }), _vm._m(0), _vm._m(1)])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('label', {
    attrs: {
      "for": "fileUpload1"
    }
  }, [_vm._v("Click to upload")]), _vm._v(" or drag and drop")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" File format: XML "), _c('br'), _vm._v(" max 5 MB ")]);
}]

export { render, staticRenderFns }