var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "xml-preview"
  }, [_vm.isLoaded ? _c('xml-page-details', {
    attrs: {
      "title": _vm.title,
      "description": _vm.description,
      "urlType": 'xml',
      "disableSaveToPdf": !_vm.structurePl.length
    }
  }) : _vm._e(), _c('div', {
    staticClass: "xml-preview__container"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _vm.isLoaded ? _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, _vm._l(_vm.structurePl, function (item, key) {
    return _c('div', {
      key: key
    }, [_vm.getType(item) === 'string' ? _c('div', {
      class: _vm.getItemClass(item),
      domProps: {
        "innerHTML": _vm._s(item)
      }
    }) : _vm.getType(item) === 'object' ? _c('b-table', {
      ref: item.fields.length === 7 ? 'table' : '',
      refInFor: true,
      attrs: {
        "fields": item.fields,
        "items": item.items,
        "tbody-tr-class": _vm.rowClass
      },
      on: {
        "input": function input($event) {
          return _vm.tableLoaded(item.fields);
        }
      }
    }) : _vm._e()], 1);
  }), 0)])]) : _vm._e()], 1), _vm.isLoaded ? _c('div', {
    staticClass: "xml-preview__options"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "xml-preview__options-inner"
  }, [_c('GenproxButton', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#app-router',
      expression: "'#app-router'"
    }],
    attrs: {
      "icon": _vm.ChevronTopIcon,
      "outline": true
    }
  }, [_vm._v("Go to top")]), _c('GenproxButton', {
    staticClass: "filled gn-primary",
    attrs: {
      "isDisabled": !_vm.structurePl.length
    },
    on: {
      "click": function click($event) {
        return _vm.printPage();
      }
    }
  }, [_vm._v("Save to pdf")]), _c('GenproxButton', {
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      "isDisabled": true,
      "outline": true
    }
  }, [_vm._v("Save to Excel")])], 1)])])])]) : _vm._e(), _vm.isLoaded ? _c('div', {
    staticClass: "xml-preview__footer"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "xml-preview__footer-inner"
  }, [_vm._m(0), _c('router-link', {
    staticClass: "xml-preview__footer-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/images/logo.png")
    }
  })])], 1)])])])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "xml-preview__footer-info"
  }, [_vm._v("Report generated by "), _c('a', {
    attrs: {
      "href": "https://fundequate.com"
    }
  }, [_vm._v("Fundequate.com ")])]);
}]

export { render, staticRenderFns }